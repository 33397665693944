
    import { defineComponent } from 'vue';
    import { chevronBackOutline, chevronForwardSharp } from 'ionicons/icons';
    import { store } from '../../store/store';
    import auth from 'authur';
    import {
        IonInput,
        IonItem,
        IonLabel,
        IonButton,
        IonIcon
    } from '@ionic/vue';
    import { MemberApiClient, GeneralApiClient } from '../../generated';
    import { LanguageMixin } from '@/mixins/LanguageMixin';
    import { SharedMixin } from '../../mixins/SharedMixin';

    export default defineComponent({
        name: 'Login',
        components: {
            IonInput,
            IonItem,
            IonLabel,
            IonButton,
            IonIcon
        },
        mixins: [SharedMixin, LanguageMixin],
        setup() {
            return {
                chevronBackOutline,
                chevronForwardSharp,
            }
        },
        ionViewDidEnter() {
            if (localStorage.getItem('userName')) {
                 // @ts-ignore
                this.userName = localStorage.getItem('userName');
            }
        },
        watch: {
            accessCode1: function (newVal, oldVal) {
                this.accessCode = this.accessCode1 + this.accessCode2 + this.accessCode3 + this.accessCode4 + this.accessCode5;
                console.log(this.accessCode)
            },   
            accessCode2: function (newVal, oldVal) {
                this.accessCode = this.accessCode1 + this.accessCode2 + this.accessCode3 + this.accessCode4 + this.accessCode5;
                console.log(this.accessCode)
            },   
            accessCode3: function (newVal, oldVal) {
                this.accessCode = this.accessCode1 + this.accessCode2 + this.accessCode3 + this.accessCode4 + this.accessCode5;
                console.log(this.accessCode)
            },
            accessCode4: function (newVal, oldVal) {
                this.accessCode = this.accessCode1 + this.accessCode2 + this.accessCode3 + this.accessCode4 + this.accessCode5;
                console.log(this.accessCode)
            },
            accessCode5: function (newVal, oldVal) {
                this.accessCode = this.accessCode1 + this.accessCode2 + this.accessCode3 + this.accessCode4 + this.accessCode5;
                if (this.accessCode.length == 5) {
                    this.submitAccessCode()
                }
            }
        },
        data() {
            return {
                fboNumber: '',
                accessCode: '',
                accessCode1: '',
                accessCode2: '',
                accessCode3: '',
                accessCode4: '',
                accessCode5: '',
                fboScreen: true,
                userName: null,
                errorMessage: false,
                registerLink: '',
                registerLinkText: ''
            }
        },
        methods: {
            goBack: function () {
                this.fboScreen = true;
            },
            submitFboNumber: async function () {
                //set screen to accesscode if it's a valid fbo number (still have to check in combo with zipcode if valid?)
                if (typeof +this.fboNumber == "number") {
                    this.fboScreen = !this.fboScreen;
                    this.focusOnFirstField('field1')
                }

            },
            submitAccessCode: async function () {

                const authenticate = await auth.authenticate({ username: this.fboNumber, password: this.accessCode })
                    .then(async () => {
                        const isAuthenticated = await auth.isAuthenticated();
                        if (isAuthenticated) {
                            const client = new MemberApiClient();
                            client.getInfo().then(result => {
                                if (result != null) {
                                    console.log('Logged in for', result.name);
                                    console.log('Logged in for', result.fBONummer);

                                    store.state.loggedInUser = result;
                                    console.log(result)

                                    if (result.name != undefined && result.fBONummer != undefined && result.photo != undefined) {
                                        localStorage.setItem('userName', result.name);
                                        localStorage.setItem('fboNummer', result.fBONummer);
                                        localStorage.setItem('photo', result.photo);

                                        if (result.favoriteEvents != undefined) {
                                            localStorage.setItem('favoriteEvents', result.favoriteEvents);
                                        }
                                        if (result.favoritePublications != undefined) {
                                            localStorage.setItem('favoritePublications', result.favoritePublications);
                                        }
                                    }

                                    const cookie = this.getCookie("privacy-accepted");

                                    this.errorMessage = false;

                                    if (cookie == "null") {
                                        this.$router.push('/privacy').then(
                                            () => {
                                                window.location.reload();
                                            }
                                        );
                                    } else {
                                        this.$router.push('/home').then(
                                            () => {
                                                window.location.reload();
                                            }
                                        );
                                    }
                                }
                            });
                        } else {
                            // laat foutmelding zien
                            this.errorMessage = true;
                        }
                    });
            },
            focusOnFirstField: function (element: string) {
                setTimeout(function () {
                    const firstField: HTMLElement = document.getElementById(element) as HTMLElement;
                    firstField.focus();
                }, 100);
            },
            gotoNextField: function (e: Event) {
                const element: HTMLElement = e.srcElement as HTMLElement;

                 // @ts-ignore
                if (e?.target?.value.length > 0 ) {
                    // @ts-ignore
                    element.parentElement.nextElementSibling?.childNodes[0].focus();
                }

            },
            deleteOrPreviousField: function (e: Event) {
                const element: HTMLElement = e.srcElement as HTMLElement;

                // @ts-ignore
                if (e?.target?.value.length < 1) {
                    // @ts-ignore
                    element.parentElement.previousElementSibling?.childNodes[0].focus();
                }
            }
        },
        mounted() {
            const generalApiClient = new GeneralApiClient();
            generalApiClient.getRegisterLink(store.state.currentLanguage).then(
                result => {
                    this.registerLink = result ?? '';
                });
            generalApiClient.getRegisterLinkText(store.state.currentLanguage).then(
                result => {
                    this.registerLinkText = result ?? '';
                });

            const cookie = this.getCookie("privacy-accepted");

            if (cookie != "null" && localStorage.getItem('loggedIn') == 'true') {

                this.$router.push('/home').then(
                    () => {
                        window.location.reload()
                    }
                );
            }
        }
    });
